.container {
  width: 100vw;
  height: 100vh;
  padding: 18px;
  overflow-y: auto;
}

.list {
  margin: 6px 0px;
}

.listItem {
  font-size: 14px;
}

.feedback {
  font-size: 14px;
}

a {
  font-size: 14px;
}

.container button {
  color: black;
  background-color:#e6e6e6;
  border: #F8E71C 1px solid;
  font-size: 18px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  padding: 0px;
  margin: 12px;

}

.container button:hover {
  background-color: rgb(211, 211, 211);
}

.title {
  font-size: 22px;
  font-weight: 700;
  margin: 16px 0 16px 18px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.heading {
  font-size: 16px;
  font-weight: 700;
  margin-left: 18px;
}

.container img {
  display: flex;
  align-self: center;
  height: 100%;
  width: auto;
  max-width: 100px;
  max-height: 100px;
  margin: 0 0 20px 0;
  animation-duration: 0.4s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease;
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter:    grayscale(100%);
  -ms-filter:     grayscale(100%);
  -o-filter:      grayscale(100%);
}

.container  p {
  font-size: 22px;
  color: rgba(255,255,255,0.5);
}

@keyframes pulse {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.15);
  }
}

