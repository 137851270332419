.navigationContainer {
  width: 100%;
  background-color: black;
  border: #8c8c8c 1px solid;
  border-radius: 15px;
  padding: 8px;
}

.welcomeMessage {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  width: 100%;
  height: auto;
  font-size: 28px;
}

.artwork {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-right: 34px;
  margin-top: 8px;
}

.artwork img {
  width: auto;
  height: auto;
  align-self: center;
  max-height: 100%;
  max-width: 500px;
  user-select: none;
}

/**
 * Special
 */

::-moz-selection { 
  background: #fd5750; 
  color: #ffffff;
  opacity: 1;
}

::selection { 
  background: #fd5750; 
  color: #ffffff;
  opacity: 1;
}

.wordEntryWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
}

.wordEntry {
  font-size: 28px;
  font-weight: 700;
  height: 58px;
  width: 300px;
  border-bottom: 1px #F8E71C solid;
}

.buttonWrapper {
  align-items: center;
  align-content: center;
  margin-top: -18px;
  pointer-events: none;
}

.buttonSpacer {
  width: 50px;
}

.letterButton {
  color: black;
  background-color:#e6e6e6;
  font-size: 24px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  outline: none;
  cursor: pointer;
  margin-left: 40px;
  margin-right: 40px;
  font-weight: 700;
  border: #F8E71C 1px solid;
  pointer-events: all;
  text-align: center;
  padding: 0px;
}

.letterButton span {
  color: black;
  padding: 0px;
  position: relative;
  top: 2px;
}

.magicLetterButton {
  color: black;
  font-size: 24px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  border: #F8E71C 1px solid;
  background-color: #F8E71C;
  pointer-events: all;
  padding: 0px;
}

.magicLetterButton:hover {
  background-color: #e9d81d;
}

.letterButton:hover {
  background-color: rgb(211, 211, 211);
}

.magicLetterButton span {
  color: black;
  padding: 0px;
  position: relative;
  top: 2px;
}

.errorWrapper {
  text-align: center;
  height: 58px;
  font-size: 22px;
  margin-top: 16px;
}

.errorMessage {
  color: #F8E71C;
}

.gameStats {
  margin-bottom: 18px;
  width: 300px;
  padding: 8px;
  border: #8c8c8c 1px solid;
  border-radius: 10px;
  outline: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.answersWrapper {
  margin-bottom: 18px;
  width: 300px;
  padding: 8px;
  border: #8c8c8c 1px solid;
  border-radius: 10px;
  outline: none;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.words {
  font-weight: 400 !important;
  font-size: 18px !important;
}

.words span {
  font-weight: 400 !important;
  font-size: 18px !important;
}

.points {
  color: #F8E71C !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  display: flex;
  justify-content: space-between;
}

.points div {
  color: #F8E71C !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 30%;
  text-align: center;
}

.timer {
  color: #F8E71C !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  width: 200px;
}

.loadingWrapper {
  text-align: center;
  margin-top: 48px;
  width: 100%;
  height: calc(100% - 40px);
}

.loadingMessage {
  font-size: 22px;
  color: #F8E71C;
  margin-top: 16px;
}

.actionButtonWrapper {
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

.shuffleButton {
  color: black;
  background-color:#e6e6e6;
  font-size: 24px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  border: #F8E71C 1px solid;
  pointer-events: all;
  text-align: center;
  padding: 8px 0px 0px 0px;
}

.actionButton {
  color: black;
  background-color:#e6e6e6;
  border: #F8E71C 1px solid;
  font-size: 18px;
  width: 108px;
  height: 60px;
  border-radius: 60px;
  outline: none;
  cursor: pointer;
  font-weight: 700;
}

.letterButtonContainer {
  width: 300px;
  text-align: center;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.topNav {
  display: flex;
  justify-content: space-between;
}

.navButton {
  padding: 4px;
  margin: 4px 4px;
}

.magicLetterEntry {
  color: #F8E71C
}